export default class SessionModel {
  constructor (data) {
    this.id = ''
    this.tripGroupId = ''
    this.dayId = ''
    this.name = ''
    this.startTime = ''
    this.endTime = ''
    this.startDate = ''
    this.endDate = ''
    this.description = ''
    this.roomName = ''
    this.url = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        name: data.Name,
        tripGroupId: data.TripGroupId,
        dayId: data.DayId,
        startTime: (new Date(data.StartTime)).toLocaleTimeString('pt'),
        endTime: (new Date(data.EndTime)).toLocaleTimeString('pt'),
        startDate: SessionModel.setStartDate(data.StartTime),
        endDate: SessionModel.setStartDate(data.EndTime),
        description: data.Description,
        roomName: data.RoomName,
        url: data.Url
      })
    }
  }

  static setStartDate (data) {
    return new Date(data)?.toLocaleDateString().toLowerCase() === 'Invalid Date'.toLowerCase() ? '' : data.split('T')[0]
  }
}
