export default class TripGroupModel {
  constructor (data) {
    this.id = ''
    this.tripId = ''
    this.emblyEventId = ''
    this.name = ''
    this.title = ''
    this.eventStartDate = ''
    this.eventEndDate = ''
    this.departureTime = ''
    this.arrivalTime = ''
    this.bodyInscription = ''
    this.bodyRegister = ''
    this.bodyRegisterCollaborator = ''
    this.bodyValidation = ''
    this.description = ''
    this.pdf = ''
    this.subjectInscription = ''
    this.subjectRegister = ''
    this.subjectRegisterCollaborator = ''
    this.subjectValidation = ''
    this.timezoneId = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        emblyEventId: data.EmblyEventId,
        id: data.Id,
        name: data.Name,
        title: data.Title,
        arrivalTime: data.ArrivalTime,
        bodyInscription: data.BodyInscription,
        bodyRegister: data.BodyRegister,
        bodyRegisterCollaborator: data.BodyRegisterCollaborator,
        bodyValidation: data.BodyValidation,
        departureTime: data.DepartureTime,
        description: data.Description,
        eventEndDate: data.EventEndDate,
        eventStartDate: data.EventStartDate,
        pdf: data.Pdf,
        subjectInscription: data.SubjectInscription,
        subjectRegister: data.SubjectRegister,
        subjectRegisterCollaborator: data.SubjectRegisterCollaborator,
        subjectValidation: data.SubjectValidation,
        timezoneId: data.TimezoneId,
        tripId: data.TripId
      })
    }
  }
}
