export default class ParticipantModel {
  constructor (data) {
    this.name = ''
    this.hasCompanion = false
    this.nif = ''
    this.email = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        name: data.Name,
        hasCompanion: data.HasCompanion,
        nif: data.NIF,
        email: data.Email
      })
    }
  }
}
