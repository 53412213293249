// Calendar
export const SET_SCHEDULE = 'SET_SCHEDULE'
export const SET_SCHEDULE_DAY = 'SET_SCHEDULE_DAY'
export const SET_SCHEDULE_DAY_VALUE = 'SET_SCHEDULE_DAY_VALUE'
export const SET_SCHEDULE_SESSION = 'SET_SCHEDULE_SESSION'
export const SET_SCHEDULE_SESSION_VALUE = 'SET_SCHEDULE_SESSION_VALUE'
export const SET_INFOS = 'SET_INFOS'
export const SET_INFO_VALUE = 'SET_INFO_VALUE'
export const SET_INFO = 'SET_INFO'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATION_VALUE = 'SET_NOTIFICATIONS_VALUE'
export const SET_GALLERY = 'SET_GALLERY'
export const SET_TRIP_GROUP_BY_NAME = 'SET_TRIP_GROUP_BY_NAME'

export default {
  SET_SCHEDULE,
  SET_SCHEDULE_DAY,
  SET_SCHEDULE_DAY_VALUE,
  SET_SCHEDULE_SESSION,
  SET_SCHEDULE_SESSION_VALUE,
  SET_INFOS,
  SET_INFO_VALUE,
  SET_INFO,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_VALUE,
  SET_GALLERY,
  SET_TRIP_GROUP_BY_NAME
}
