import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/auth',
    name: 'auth',
    redirect: '/login',
    component: () => import('../views/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/LoginView.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('../views/layouts/MainLayout.vue'),
    redirect: '/trips-list/1',
    children: [
      {
        path: '/trips-list/:page',
        name: 'tripsList',
        component: () => import('../views/trips/TripsListView.vue')
      },
      {
        path: '/users-list',
        name: 'usersList',
        meta: {
          restricted: true
        },
        component: () => import('../views/administration/UsersView.vue')
      },
      {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        meta: {
          restricted: true
        },
        component: () => import('../views/administration/TermsView.vue')
      },
      {
        path: '/rgpd-manager',
        name: 'RGPDManager',
        meta: {
          restricted: true
        },
        component: () => import('../views/administration/RGPDManagerView')
      }
    ]
  },
  {
    path: '/trip',
    component: () => import('../views/layouts/DefaultLayout.vue'),
    redirect: '/create-trip',
    children: [
      {
        path: '/create-trip',
        name: 'createTrip',
        meta: {
          restricted: true
        },
        component: () => import('../views/trips/CreateTripView.vue')
      },
      {
        path: '/edit-trip/:tripId',
        name: 'editTrip',
        component: () => import('../views/trips/EditTripView.vue')
      }
    ]
  },
  {
    path: '/manage-guests/:tripId',
    name: 'manageGuests',
    meta: {
      restricted: true
    },
    component: () => import('../views/trips/ManageGuestsView.vue')
  },
  {
    path: '/trip-group',
    component: () => import('../views/layouts/TripGroupLayout.vue'),
    children: [
      {
        path: '/create-group',
        name: 'createGroup',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/CreateGroupView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/details',
        name: 'editGroup',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/EditGroupView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/calendar',
        name: 'editGroupCalendar',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/EditGroupCalendarView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/informations',
        name: 'editGroupInformations',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/EditGroupInformationsView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/participants',
        name: 'editGroupParticipants',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/EditGroupParticipantsView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/notifications',
        name: 'editGroupNotifications',
        meta: {
          restricted: true
        },
        component: () => import('../views/groups/EditGroupNotificationsView.vue')
      },
      {
        path: '/edit-group/:tripId/:groupId/gallery',
        name: 'editGroupGallery',
        component: () => import('../views/groups/EditGroupGalleryView.vue')
      }
    ]
  },
  {
    path: '/content-not-found',
    name: 'ContentNotFound',
    component: () => import('../views/general/ContentNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/general/NotFoundView.vue')
  }
]

const router = createRouter({
  scrollBehavior () {
    if (document.getElementById('container')) {
      document.getElementById('container').scrollIntoView({ block: 'start' })
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAdmin = parseInt(Cookies.get('TAR'), 10) === 1
  const isAgent = parseInt(Cookies.get('TAR'), 10) === 5
  if (!to.meta.restricted || (to.meta.restricted && (isAdmin || isAgent))) {
    next()
  } else {
    next('/')
  }
})

export default router
