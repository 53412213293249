import { SET_SCHEDULE, SET_SCHEDULE_DAY, SET_SCHEDULE_DAY_VALUE, SET_SCHEDULE_SESSION, SET_SCHEDULE_SESSION_VALUE, SET_NOTIFICATIONS, SET_NOTIFICATION_VALUE, SET_INFOS, SET_INFO_VALUE, SET_INFO, SET_GALLERY } from './types'

// Calendar
export const setTripGroupSchedule = ({ commit }, payload) => {
  commit(SET_SCHEDULE, payload)
}
export const setTripGroupScheduleDay = ({ commit }, payload) => {
  commit(SET_SCHEDULE_DAY, payload)
}
export const setTripGroupScheduleDayValue = ({ commit }, payload) => {
  commit(SET_SCHEDULE_DAY_VALUE, payload)
}
export const setTripGroupScheduleSession = ({ commit }, payload) => {
  commit(SET_SCHEDULE_SESSION, payload)
}
export const setTripGroupScheduleSessionValue = ({ commit }, payload) => {
  commit(SET_SCHEDULE_SESSION_VALUE, payload)
}
export const setTripGroupNotifications = ({ commit }, payload) => {
  commit(SET_NOTIFICATIONS, payload)
}
export const setTripGroupNotification = ({ commit }, payload) => {
  commit(SET_NOTIFICATION_VALUE, payload)
}
export const setTripGroupInfos = ({ commit }, payload) => {
  commit(SET_INFOS, payload)
}
export const setTripGroupInfo = ({ commit }, payload) => {
  commit(SET_INFO, payload)
}
export const setInfoValue = ({ commit }, payload) => {
  commit(SET_INFO_VALUE, payload)
}
export const setGallery = ({ commit }, payload) => {
  commit(SET_GALLERY, payload)
}

export default {
  setTripGroupSchedule,
  setTripGroupScheduleDay,
  setTripGroupScheduleDayValue,
  setTripGroupScheduleSession,
  setTripGroupScheduleSessionValue,
  setTripGroupNotifications,
  setTripGroupNotification,
  setTripGroupInfos,
  setInfoValue,
  setTripGroupInfo,
  setGallery
}
