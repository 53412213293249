export default class NotificationsModel {
  constructor (data) {
    this.id = ''
    this.message = ''
    this.state = ''
    this.date = ''
    this.scheduleDate = ''
    this.scheduleTime = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        message: data.Message,
        state: data.State,
        date: `${(new Date(data.SendDate)).toISOString().split('T')[0]}   ${(new Date(data.SendDate)).toLocaleTimeString([], { timeStyle: 'short' })}`,
        scheduleDate: data.ScheduleDate,
        scheduleTime: data.ScheduleHour
      })
    }
  }
}
