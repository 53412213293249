<template>
  <q-layout view="hhr lpR lFf">
    <router-view></router-view>
  </q-layout>
  <q-dialog v-model="onLine" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="signal_wifi_off" color="primary" text-color="white" />
        <span class="q-ml-sm">Actualmente não está ligado a nenhuma rede. Por favor, verifique a sua ligação e tente novamente.</span>
      </q-card-section>
    </q-card>
  </q-dialog>

</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue'

export default {
  name: 'LayoutDefault',

  setup () {
    const onLine = ref(false)

    function internetSignal () {
      if (navigator.onLine) {
        onLine.value = false
      } else {
        onLine.value = true
      }
    }
    onMounted(() => {
      window.addEventListener('online', internetSignal)
      window.addEventListener('offline', internetSignal)
    })
    onBeforeUnmount(() => {
      window.addEventListener('online', internetSignal)
      window.addEventListener('offline', internetSignal)
    })

    return {
      onLine,
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
