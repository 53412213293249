export default class RolesModel {
  constructor (data) {
    this.id = ''
    this.name = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        name: data.Name
      })
    }
  }
}
