export default class GalleryModel {
  constructor (data) {
    this.id = ''
    this.url = ''
    this.creationDate = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        url: data.Url,
        creationDate: data.CreationDate
      })
    }
  }
}
