import DayModel from '@/enteties/Day'
import SessionModel from '@/enteties/Session'
import InfoModel from '@/enteties/Info'
import NotificationsModel from '@/enteties/Notification'
import TripGroupByTripModel from '@/enteties/TripGroup'

export default {
  // Calendar
  schedule: [],
  day: new DayModel(),
  session: new SessionModel(),
  infos: [],
  info: new InfoModel(),
  sentNotifications: [],
  scheduledNotifications: [],
  notification: new NotificationsModel(),
  gallery: [],
  groups: new TripGroupByTripModel()
}
