import { SET_TIMEZONES, SET_USERS, SET_LEGAL_DOC, SET_DOC_VALUE, SET_USER, SET_ROLES, SET_PROFILE, SET_PARTICIPANT, SET_PARTICIPANTS } from './types'

export const setTimezones = ({ commit }, payload) => {
  commit(SET_TIMEZONES, payload)
}

export const setUsers = ({ commit }, payload) => {
  commit(SET_USERS, payload)
}

export const setLegalDoc = ({ commit }, payload) => {
  commit(SET_LEGAL_DOC, payload)
}

export const setDocValue = ({ commit }, payload) => {
  commit(SET_DOC_VALUE, payload)
}

export const setParticipant = ({ commit }, payload) => {
  commit(SET_PARTICIPANT, payload)
}

export const setParticipants = ({ commit }, payload) => {
  commit(SET_PARTICIPANTS, payload)
}

export const setUser = ({ commit }, payload) => {
  commit(SET_USER, payload)
}

export const setRoles = ({ commit }, payload) => {
  commit(SET_ROLES, payload)
}

export const setProfile = ({ commit }, payload) => {
  commit(SET_PROFILE, payload)
}

export default {
  setTimezones,
  setUsers,
  setLegalDoc,
  setDocValue,
  setParticipant,
  setParticipants,
  setUser,
  setRoles,
  setProfile
}
