export default class TripGuestModel {
  constructor (data) {
    this.allowedCompanions = 0
    this.birthdate = ''
    this.businessUnit = null
    this.checkList = {}
    this.comercialUnit = null
    this.citizenshipCardURL = null
    this.citizenshipId = null
    this.citizenshipValidationDate = null
    this.companionCount = 0
    this.companionIds = []
    this.companions = []
    this.email = null
    this.contactEmail = null
    this.firstNames = ''
    this.guestActive = false
    this.guestType = null
    this.id = ''
    this.invalidationDescription = null
    this.invitationEmail = null
    this.isCompanion = null
    this.pictureURL = null
    this.store = null
    this.messageId = ''
    this.registrationDate = ''
    this.guestStatus = null
    this.ranking = null
    this.nationality = null
    this.phoneNumber = null
    this.hobbies = null
    this.professionalDescription = null
    this.personalityDescription = null
    this.tripExpectationDescription = null
    this.foodRestriction = null
    this.travelAppDisplayName = null
    this.internalNotes = null
    this.invitationStore = null
    this.manager = null
    this.nif = null
    this.insuranceNIF = null
    this.mediatorId = null
    this.passportId = null
    this.passportValidationDate = null
    this.passportPictureURL = null
    this.roomType = 5
    this.roomName = ''
    this.tripId = ''
    this.tripGroupId = ''
    this.tripGroupName = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        tripGroupId: data.TripGroupId,
        tripGroupName: data.TripGroupName,
        pictureURL: data.PictureURL,
        allowedCompanions: data.AllowedCompanions,
        businessUnit: TripGuestModel.getBusinessUnit(data),
        store: TripGuestModel.getStore(data),
        guestActive: data.GuestActive,
        guestType: data.GuestType,
        comercialUnit: data.ComercialUnit,
        companionCount: data.CompanionCount,
        email: data.Email,
        contactEmail: data.ContactEmail,
        firstNames: TripGuestModel.getFirstNames(data),
        tripId: data.TripId,
        invitationEmail: data.InvitationEmail,
        messageId: data.MessageId,
        registrationDate: data.RegistrationDate,
        guestStatus: data.GuestStatus,
        invalidationDescription: data.InvalidationDescription,
        checkList: data.CheckList,
        ranking: data.Ranking,
        birthdate: data.Birthdate,
        nationality: data.Nationality,
        phoneNumber: data.PhoneNumber,
        hobbies: data.Hobbies,
        professionalDescription: data.ProfessionalDescription,
        personalityDescription: data.PersonalityDescription,
        tripExpectationDescription: data.TripExpectationDescription,
        foodRestriction: data.FoodRestriction,
        travelAppDisplayName: data.TravelAppDisplayName,
        internalNotes: data.InternalNotes,
        invitationStore: data.InvitationStore,
        manager: data.Manager,
        nif: data.NIF,
        insuranceNIF: data.InsuranceNIF,
        mediatorId: data.MediatorId,
        citizenshipId: data.CitizenshipId,
        citizenshipCardURL: data.CitizenshipCardURL,
        citizenshipValidationDate: data.CitizenshipValidationDate,
        passportId: data.PassportId,
        passportValidationDate: data.PassportValidationDate,
        passportPictureURL: data.PassportPictureURL,
        roomType: data.RoomType,
        roomName: TripGuestModel.roomNamesByType(data.RoomType),
        isCompanion: data.IsCompanion,
        companionIds: data.CompanionIds,
        companions: data.Companions?.map((companion) => new TripGuestModel(companion))
      })
    }
  }

  static setCompanions (data) {
    data.Companions.map((companion) => new TripGuestModel(companion))
  }

  static roomNamesByType (data) {
    switch (data) {
      case 2:
        return 'Double'
      case 3:
        return 'Twin'
      case 4:
        return 'Single'
      default:
        return ''
    }
  }

  static getFirstNames (data) {
    if (data.FirstNames?.length) {
      return data.FirstNames
    }

    return data.GuestType === 2 ? 'Sem Name' : 'Participante por atribuir'
  }

  static getStore (data) {
    return data.Store?.length ? data.Store : ''
  }

  static getBusinessUnit (data) {
    return data.BusinessUnit?.length ? data.BusinessUnit : ''
  }
}
