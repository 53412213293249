export default class SimpleTripModel {
  constructor (data) {
    this.id = ''
    this.name = ''
    this.isComplete = null
    this.creationDate = ''
    this.image = ''
    this.headerImage = ''
    this.guestCount = 0
    this.guestConfirmedCount = 0
    this.guestConfirmedPercent = 0

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        name: data.Name,
        isComplete: data.IsComplete,
        creationDate: data.CreationDate,
        image: data.Image,
        headerImage: data.HeaderImage,
        guestCount: data.GuestCount,
        guestConfirmedCount: data.GuestConfirmedCount,
        guestConfirmedPercent: data.GuestConfirmedPercent
      })
    }
  }
}
