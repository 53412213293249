import {
  SET_TRIP,
  SET_TRIP_GROUP,
  SET_TRIPS,
  SET_TRIP_VALUE,
  SET_TRIP_GROUP_VALUE,
  SET_TRIP_GUESTS,
  SET_TRIP_GUEST,
  SORT_TRIP_GUESTS,
  SET_GUEST_VALUE
} from './types'

export const setTrip = ({ commit }, payload) => {
  commit(SET_TRIP, payload)
}

export const setTripGroup = ({ commit }, payload) => {
  commit(SET_TRIP_GROUP, payload)
}

export const setTripGroupValue = ({ commit }, payload) => {
  commit(SET_TRIP_GROUP_VALUE, payload)
}

export const setTrips = ({ commit }, payload) => {
  commit(SET_TRIPS, payload)
}

export const setTripValue = ({ commit }, payload) => {
  commit(SET_TRIP_VALUE, payload)
}

export const setTripGuests = ({ commit }, payload) => {
  commit(SET_TRIP_GUESTS, payload)
  commit(SORT_TRIP_GUESTS, { field: 'invited', value: 'type' })
}

export const setTripGuest = ({ commit }, payload) => {
  commit(SET_TRIP_GUEST, payload)
}

export const sortTripGuests = ({ commit }, payload) => {
  commit(SORT_TRIP_GUESTS, payload)
}

export const setGuestValue = ({ commit }, payload) => {
  commit(SET_GUEST_VALUE, payload)
}

export default {
  setTrip,
  setTripGroup,
  setTripGroupValue,
  setTrips,
  setTripValue,
  setTripGuests,
  setTripGuest,
  sortTripGuests,
  setGuestValue
}
