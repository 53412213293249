export const SET_TRIP = 'SET_TRIP'
export const SET_TRIP_GROUP = 'SET_TRIP_GROUP'
export const SET_TRIPS = 'SET_TRIPS'
export const SET_TRIP_VALUE = 'SET_TRIP_VALUE'
export const SET_TRIP_GROUP_VALUE = 'SET_TRIP_GROUP_VALUE'
export const SET_TRIP_GUESTS = 'SET_TRIP_GUESTS'
export const SET_TRIP_GUEST = 'SET_TRIP_GUEST'
export const SORT_TRIP_GUESTS = 'SORT_TRIP_GUESTS'
export const SET_GUEST_VALUE = 'SET_GUEST_VALUE'

export default {
  SET_TRIP,
  SET_TRIP_GROUP,
  SET_TRIPS,
  SET_TRIP_VALUE,
  SET_TRIP_GROUP_VALUE,
  SET_TRIP_GUESTS,
  SET_TRIP_GUEST,
  SORT_TRIP_GUESTS,
  SET_GUEST_VALUE
}
