import TripGroupModel from './TripGroup'

export default class TripModel {
  constructor (data) {
    this.id = ''
    this.name = ''
    this.type = 2
    this.title = ''
    this.description = ''
    this.arrivalLocationEN = ''
    this.arrivalLocation = ''
    this.isComplete = null
    this.image = ''
    this.headerImage = ''
    this.subjectInvite = ''
    this.bodyInvite = ''
    this.subjectInviteCollaborator = ''
    this.bodyInviteCollaborator = ''
    this.tripGroups = []

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        name: data.Name,
        type: data.Type,
        title: data.Title,
        description: data.Description,
        arrivalLocationEN: data.ArrivalLocationEN,
        arrivalLocation: data.ArrivalLocation,
        isComplete: data.IsComplete,
        image: data.Image,
        headerImage: data.HeaderImage,
        subjectInvite: data.SubjectInvite,
        bodyInvite: data.BodyInvite,
        subjectInviteCollaborator: data.SubjectInviteCollaborator,
        bodyInviteCollaborator: data.BodyInviteCollaborator,
        tripGroups: data.TripGroups?.map(tripGroup => new TripGroupModel(tripGroup))
      })
    }
  }
}
