export default class ProfileModel {
  constructor (data) {
    this.authProvider = ''
    this.authProviderDescription = ''
    this.email = ''
    this.id = ''
    this.role = ''
    this.roleDescription = ''
    this.userName = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        authProvider: data.AuthProvider,
        authProviderDescription: data.AuthProviderDescription,
        email: data.Email,
        id: data.Id,
        role: data.Role,
        roleDescription: data.RoleDescription,
        userName: data.UserName
      })
    }
  }
}
