export default class LegalDocModel {
  constructor (data) {
    this.privacyPolicy = ''
    this.termsAndConditions = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        privacyPolicy: data.PrivacyPolicy,
        termsAndConditions: data.TermsAndConditions
      })
    }
  }
}
