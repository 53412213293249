export default class TimezoneModel {
  constructor (data) {
    this.id = ''
    this.label = ''

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.Id,
        label: data.Name
      })
    }
  }
}
