export const SET_TIMEZONES = 'SET_TIMEZONES'
export const SET_USERS = 'SET_USERS'
export const SET_LEGAL_DOC = 'SET_LEGAL_DOC'
export const SET_DOC_VALUE = 'SET_DOC_VALUE'
export const SET_USER = 'SET_USER'
export const SET_ROLES = 'SET_ROLES'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_PARTICIPANT = 'SET_PARTICIPANT'
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS'

export default {
  SET_TIMEZONES,
  SET_USERS,
  SET_LEGAL_DOC,
  SET_DOC_VALUE,
  SET_USER,
  SET_ROLES,
  SET_PROFILE,
  SET_PARTICIPANT,
  SET_PARTICIPANTS
}
