import TripGuestModel from '@/enteties/TripGuest'

export default {
  trip: {},
  tripGroup: {},
  trips: [],
  totalTrips: 0,
  tripGuests: {
    invited: [],
    registred: [],
    toValidate: [],
    confirmed: [],
    inactive: []
  },
  tripGuest: new TripGuestModel()
}
