export const getTimezones = state => state.timezones
export const getUsers = state => state.users
export const getLegalDoc = state => state.legalDoc
export const getUser = state => state.user
export const getTotalUsers = state => state.totalUsers
export const getRoles = state => state.roles
export const getRole = state => state.user.role
export const isAdmin = state => state.profile.role === 1
export const isAgent = state => state.profile.role === 5
export const getProfileUsername = state => state.profile.username
export const getParticipant = state => state.participant
export const getParticipants = state => state.participants
export const getParticipantsTotal = state => state.participantsTotal

export default {
  getTimezones,
  getUsers,
  getTotalUsers,
  getLegalDoc,
  getUser,
  getRoles,
  getRole,
  isAdmin,
  isAgent,
  getProfileUsername,
  getParticipant,
  getParticipants,
  getParticipantsTotal
}
