// Calendar
export const getSchedule = state => state.schedule
export const getScheduleDay = state => state.day
export const getScheduleSession = state => state.session
export const getSentNotifications = state => state.sentNotifications
export const getScheduledNotifications = state => state.scheduledNotifications
export const getNotification = state => state.notification
export const getTripGroupInfos = state => state.infos
export const getTripGroupInfo = state => state.info
export const getGallery = state => state.gallery
export const getTripGroupByTrip = state => state.groups

export default {
  getSchedule,
  getScheduleDay,
  getScheduleSession,
  getSentNotifications,
  getNotification,
  getTripGroupInfos,
  getTripGroupInfo,
  getGallery,
  getTripGroupByTrip,
  getScheduledNotifications
}
