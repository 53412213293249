export const getTrip = state => state.trip
export const getTrips = state => state.trips
export const getNumberOfTrips = state => state.totalTrips
export const getTripGroup = state => state.tripGroup

export const getAllTripGuests = state => state.tripGuests
export const getGuest = state => state.tripGuest
export const getInvitedGuests = state => state.tripGuests.invited
export const getRegisteredGuests = state => state.tripGuests.registred
export const getToValidateGuests = state => state.tripGuests.toValidate
export const getConfirmedGuests = state => state.tripGuests.confirmed
export const getInactiveGuests = state => state.tripGuests.inactive

export default {
  getTrip,
  getTrips,
  getNumberOfTrips,
  getTripGroup,
  getAllTripGuests,
  getGuest,
  getInvitedGuests,
  getRegisteredGuests,
  getToValidateGuests,
  getConfirmedGuests,
  getInactiveGuests
}
