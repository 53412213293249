import SimpleTripModel from '@/enteties/SimpleTrip'
import TripModel from '@/enteties/Trip'

import {
  SET_TRIP,
  SET_TRIP_GROUP,
  SET_TRIPS,
  SET_TRIP_VALUE,
  SET_TRIP_GROUP_VALUE,
  SET_TRIP_GUESTS, SET_TRIP_GUEST, SORT_TRIP_GUESTS, SET_GUEST_VALUE
} from './types'
import TripGuestModel from '@/enteties/TripGuest'

export default {
  [SET_TRIP] (state, payload) {
    state.trip = new TripModel(payload)
  },

  [SET_TRIP_GROUP] (state, payload) {
    state.tripGroup = payload
  },

  [SET_TRIP_GROUP_VALUE] (state, payload) {
    state.tripGroup[payload.field] = payload.value
  },

  [SET_TRIPS] (state, payload) {
    state.trips = payload.Itens.map(item => new SimpleTripModel(item))
    state.totalTrips = payload.Total
  },

  [SET_TRIP_VALUE] (state, payload) {
    state.trip[payload.field] = payload.value
  },

  [SET_TRIP_GUESTS] (state, payload) {
    state.tripGuests.invited = payload.Invited.map(guest => new TripGuestModel(guest))
    state.tripGuests.registred = payload.Registred.map(guest => new TripGuestModel(guest))
    state.tripGuests.toValidate = payload.ToValidate.map(guest => new TripGuestModel(guest))
    state.tripGuests.confirmed = payload.Confirmed.map(guest => new TripGuestModel(guest))
    state.tripGuests.inactive = payload.Inactive.map(guest => new TripGuestModel(guest))
  },

  [SET_TRIP_GUEST] (state, payload) {
    state.tripGuest = new TripGuestModel(payload)
  },

  [SORT_TRIP_GUESTS] (state, payload) {
    state.tripGuests[payload.field].sort((a, b) => {
      if (payload.value === 'type') {
        return a.guestType - b.guestType
      }

      return payload.value === 'az' ? a.firstNames - b.firstNames : a.firstNames + b.firstNames
    })
  },

  [SET_GUEST_VALUE] (state, payload) {
    if (payload.isCompanion) {
      state.tripGuest.companions[0][payload.field] = payload.value
    } else {
      state.tripGuest[payload.field] = payload.value
    }
  }
}
