import { SET_SCHEDULE, SET_SCHEDULE_DAY, SET_SCHEDULE_DAY_VALUE, SET_SCHEDULE_SESSION, SET_SCHEDULE_SESSION_VALUE, SET_INFOS, SET_INFO_VALUE, SET_INFO, SET_NOTIFICATIONS, SET_NOTIFICATION_VALUE, SET_GALLERY } from './types'
import DayModel from '@/enteties/Day'
import InfoModel from '@/enteties/Info'
import NotificationsModel from '@/enteties/Notification'
import GalleryModel from '@/enteties/Gallery'

export default {
  // Calendar
  [SET_SCHEDULE] (state, payload) {
    state.schedule = []
    state.schedule = payload.Days.map(day => new DayModel(day))
  },
  [SET_SCHEDULE_DAY] (state, payload) {
    state.day = payload
  },
  [SET_SCHEDULE_DAY_VALUE] (state, payload) {
    state.day[payload.field] = payload.value
  },
  [SET_SCHEDULE_SESSION] (state, payload) {
    state.session = payload
  },
  [SET_SCHEDULE_SESSION_VALUE] (state, payload) {
    state.session[payload.field] = payload.value
  },
  [SET_INFOS] (state, payload) {
    state.infos = payload.map(info => new InfoModel(info))
  },
  [SET_INFO] (state, payload) {
    state.info = payload
  },
  [SET_INFO_VALUE] (state, payload) {
    state.info[payload.field] = payload.value
  },
  [SET_NOTIFICATIONS] (state, payload) {
    state.sentNotifications = payload.Sent.map(notif => new NotificationsModel(notif))
    state.scheduledNotifications = payload.Scheduled.map(notif => new NotificationsModel(notif))
  },
  [SET_NOTIFICATION_VALUE] (state, payload) {
    state.notification[payload.field] = payload.value
  },
  [SET_GALLERY] (state, payload) {
    state.gallery = payload.Itens.map(item => new GalleryModel(item))
  }
}
